<template>
<div>
  <div class="font-bold text-3xl mb-3">
    {{title}}
  </div>
  <div class="flex justify-start">
    <div class="text-zinc-400 little-text-color flex is-justify-space-between items-center" style="color: rgb(113 113 122)">
      owned by <span class="text-sm text-blue-400 ml-4">{{owner.slice(0,6)}}...{{owner.slice(38,42)}}</span> <add-follow class="ml-4" :address="owner"></add-follow>
    </div>
    <div class="ml-8 flex">
      <img class="w-5" src="../../assets/public/chat-quote.svg">
      <span class="ml-2 text-zinc-400" style="color: rgb(113 113 122)">52</span>
    </div>
  </div>
</div>
</template>

<script>
import AddFollow from "../follows/addFollow";
export default {
  name: "metaHeader",
  components: {AddFollow},
  props:[
      "title",
      "owner"
  ]
}
</script>

<style lang="scss" scoped>

</style>