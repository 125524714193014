<template>
  <div>
    <el-page-header class="ml-3" @back="goBack" content="详情页面"></el-page-header>
    <div v-if="tokenId!==-1">
      <div class="flex justify-evenly items-start mt-4" v-for="item in tokenIdFathersDatas">
        <nft-content :img-data="item.image"></nft-content>
        <div>
          <meta-header :title="item.name" :owner="item.owner" class="mb-6 mt-12"></meta-header>
          <nft-meta :token-id="item.tokenId"  :description="item.description"></nft-meta>
        </div>
      </div>
      <div class="mt-10 mb-10">
        <CreateNFT v-if="connected" :token-id-from="tokenId"></CreateNFT>
        <connect-wallet v-else></connect-wallet>
      </div>
<!--      <connect-wallet v-else></connect-wallet>-->
    </div>
    <el-empty v-else description="需要从某个nft跳转过来才可以喔"></el-empty>
  </div>
</template>

<script>
import nftContent from "../components/writeNext/nftContent"
import nftMeta from "../components/writeNext/nftMeta"
import metaHeader from "../components/writeNext/metaHeader";
import CreateNFT from "./create"
import {mapState} from "vuex";
import ConnectWallet from "../components/myself/connectWallet";

import connectTextABI from "../abi/connectText.json";
import gsnABI from "../abi/gsn.json";
import followABI from "../abi/follow.json";

export default {
  name: "WriteNext",
  // props:["tokenId"],
  computed:mapState(["connectTextContract","connected","bscTestNet","connectTextAddress","gsnAddress","followAddress","followContract","gsnContract"]),
  data(){
    return {
      tokenIdFathers:[],
      tokenIdFathersDatas:[],
      tokenId:0
    }
  },
  methods:{
    parseData(data){
      const json = Buffer.from(data.substring(29), "base64").toString()
      console.log(json)
      return JSON.parse(json)
    },
    goBack(){
      this.$router.back()
    }
  },
  components:{
    ConnectWallet,
    nftMeta,
    nftContent,
    metaHeader,
    CreateNFT
  },
  async created(){
    let tokenId=this.$route.query.tokenId
    if(tokenId!==undefined){
      if(this.connectTextContract===undefined||this.connectTextContract===null){
        let provider=new this.ethers.providers.JsonRpcProvider(this.bscTestNet)
        this.$store.state.provider=provider

        this.$store.state.connectTextContract=new this.ethers.Contract(this.connectTextAddress,connectTextABI.abi,provider)

        if(this.followContract===undefined||this.followContract===null) this.$store.state.followContract=new this.ethers.Contract(this.followAddress,followABI.abi,provider)
        if(this.gsnContract===undefined||this.gsnContract===null) this.$store.state.gsnContract=new this.ethers.Contract(this.gsnAddress,gsnABI.abi,provider)
      }
      console.log(tokenId,this.connectTextContract)
      let tokenIDFathers=await this.connectTextContract.getTokenFrom(tokenId)
      console.log(tokenIDFathers)
      this.tokenId=parseInt(tokenId)

      var newArry = [];
      for (let i = tokenIDFathers.length - 1; i >= 0; i--) {
        newArry[newArry.length] = tokenIDFathers[i];
      }
      tokenIDFathers=newArry
      // tokenIDFathers.reverse()
      for(let i of tokenIDFathers){
        console.log(i)
        let dataURI=await this.connectTextContract.tokenURI(i.toNumber())
        let result=this.parseData(dataURI)
        result["tokenId"]=i.toNumber()
        result["owner"]=await this.connectTextContract.ownerOf(i.toNumber())
        this.tokenIdFathersDatas.push(result)
      }
      this.tokenIdFathers=tokenIDFathers
      console.log(this.tokenIdFathers)
    }

  }
}
</script>

<style scoped>

</style>