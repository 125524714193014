<template>
<div style="width: 31rem;height: 31rem" class="shadow-lg">
  <el-image
      style="width: 100%;height: 100%;"
      fit="fill"
      :preview-src-list="[imgData]"
      :src="imgData"/>
</div>
</template>

<script>
export default {
  name: "nftContent",
  props:["imgData"]
}
</script>

<style scoped>

</style>