<template>
<!--  这个是用来展示 erc721 元数据的组件 -->
  <div class="shadow-md max-w-lg box-content" style="min-width: 32rem;">
    <el-collapse v-model="activeNames" @change="handleChange" accordion>
      <el-collapse-item title="Description" name="1">
        <div class="px-3 text-sm">
           {{description}}
        </div>
      </el-collapse-item>
      <el-collapse-item title="Attribute" name="2">
        <div class="px-3">
          <el-tag class="ml-2 mt-2" v-for="item in [1]">text</el-tag>
        </div>
      </el-collapse-item>
      <el-collapse-item title="Detail" name="3">
          <div class="p-9">
            <div class="flex flex-row justify-between items-center">
              <div class="font-bold text-base">Contract</div>
              <div class="font-medium text-sm text-blue-500">{{ connectTextAddress.slice(0,6) }}...{{connectTextAddress.slice(38,42)}}</div>
            </div>
            <div class="flex justify-between mt-1">
              <div class="font-bold text-base">tokenID</div>
              <div class="font-medium text-sm ">{{tokenId}}</div>
            </div>
            <div class="flex justify-between mt-1">
              <div class="font-bold text-base">tokenStandard</div>
              <div class="font-medium text-sm ">erc721</div>
            </div>
            <div class="flex justify-between mt-1">
              <div class="font-bold text-base">blockChain</div>
              <div>polygon</div>
            </div>
          </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "nftMeta",
  data() {
    return {
      activeNames: ['1']
    };
  },
  props:["description","tokenId"],
  computed:mapState(["connectTextAddress"]),
  methods: {
    handleChange(val) {
      console.log(val);
    }
  }
}
</script>

<style scoped>
.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both
}

.box-card {
  width: 480px;
}
</style>